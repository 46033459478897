<template>
  <div>
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <div class="date-picker-wrapper">
          <VueCtkDateTimePicker
            ref="datePicker"
            id="date_time_picker"
            class="custom-date-time-picker custom-date-time-picker--range"
            v-model="date_time_picker"
            :custom-shortcuts="custom_shortcuts"
            shortcut="thisWeek"
            @is-hidden="onIsHiddenPicker"
            format="DD.MM.YYYY"
            formatted="ll"
            label="Выберите даты"
            color="#2f73ff"
            button-color="#2f73ff"
            overlay
            no-header
            no-label
            range
          />
          <div v-if="date_time_picker_error" class="date-picker-wrapper__error">Выберите дату окончания</div>
        </div>
        <app-button @click="onAddButtonHandler" type="button" size="link">
          <img src="@/assets/img/add-icon.svg" alt="">
          Добавить расписание
        </app-button>
      </app-cells>
      <app-input
        v-model="input_search"
        @input.native="onFilterSearch($event.target.value)"
        class="input--450"
        type="search"
        placeholder="Поиск по номеру стола"
      />
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
      :limit="60"
    />
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
<!--      <div slot="h__select">-->
<!--        <label class="checkbox  checkbox&#45;&#45;table">-->
<!--          <input-->
<!--            v-model="checkedAll"-->
<!--            type="checkbox"-->
<!--            name="checked_all"-->
<!--            ref="mainCh"-->
<!--          />-->
<!--          <span class="checkbox__checkmark"></span>-->
<!--        </label>-->
<!--      </div>-->
<!--      <template slot="select" slot-scope="props">-->
<!--        <label class="checkbox  checkbox&#45;&#45;table">-->
<!--          <input-->
<!--            v-model="checked"-->
<!--            type="checkbox"-->
<!--            :value="props.row.id"-->
<!--            :name="props.row.id"-->
<!--          />-->
<!--          <span class="checkbox__checkmark"></span>-->
<!--        </label>-->
<!--      </template>-->
      <div slot="time_start" slot-scope="props">
        с {{ props.row.time_start }} по {{ props.row.time_end }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__item pagination__item--prev'"
      :next-class="'pagination__item pagination__item--next'"
      :next-link-class="'pagination__link pagination__link--next'"
      :prev-link-class="'pagination__link pagination__link--prev'"
    />

    <app-sidebar-right
      :title="!isAdd ? 'Редактирование расписания' : 'Добавление расписания'"
      :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Стол" required>
          <v-select
            v-model="sidebarData.table"
            :reduce="item => item.id"
            :options="tableList"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="number"
            placeholder="Выберите стол"
            class="select"
            :class="{ 'select--error': $v.sidebarData.table.$error }"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.number }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.number }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.sidebarData.table.$dirty && !$v.sidebarData.table.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Дата, время начала" label-for="time_start" required>
          <app-input
            v-model="sidebarData.time_start"
            v-mask="'99.99.9999 99:99'"
            placeholder="Период с"
            id="time_start"
            :error="$v.sidebarData.time_start.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.time_start.$dirty && !$v.sidebarData.time_start.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Дата, время окончания" label-for="time_end" required>
          <app-input
            v-model="sidebarData.time_end"
            v-mask="'99.99.9999 99:99'"
            placeholder="Период по"
            id="time_end"
            :error="$v.sidebarData.time_end.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.time_end.$dirty && !$v.sidebarData.time_end.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Тип депозита" required>
          <app-cells position="start">
            <app-radio
              v-if="sidebarData.type"
              v-model="sidebarData.type.id"
              value="pperson"
              name="deposit_type"
              label="За человека"
              :error="$v.sidebarData.type.$error"
            />
            <app-radio
              v-if="sidebarData.type"
              v-model="sidebarData.type.id"
              value="ptable"
              name="deposit_type"
              label="За стол"
              :error="$v.sidebarData.type.$error"
            />
          </app-cells>
          <template #error>
            <div v-if="$v.sidebarData.type.id.$dirty && !$v.sidebarData.type.id.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Размер депозита" label-for="price" required>
          <app-input
            v-model="sidebarData.price"
            placeholder="Введите размер депозита"
            id="price"
            :error="$v.sidebarData.price.$error"
          />
          <template #error>
            <div v-if="$v.sidebarData.price.$dirty && !$v.sidebarData.price.required">Обязательное поле</div>
            <div v-if="$v.sidebarData.price.$dirty && !$v.sidebarData.price.decimal">Принимаются только числовые значения через точку</div>
          </template>
        </app-form-group>
        <app-cells>
          <app-button v-if="!isAdd" ref="submit" :disabled="$v.sidebarData.$error">Сохранить</app-button>
          <app-button v-else ref="submit" :disabled="$v.sidebarData.$error">Добавить</app-button>
        </app-cells>
      </form>
      <app-button @click="onDelete" v-if="!isAdd" theme="error" ref="delete">Удалить</app-button>
    </app-sidebar-right>
    <div class="flying-block" :class="{ 'flying-block--show': is_show_flying_block }">
      <div class="flying-block__text">Выбрано: <span>{{ checked.length }}</span></div>
      <app-button @click.native="onCheckedTableItemsClick" size="small">Изменить</app-button>
    </div>
    <modal
      name="flying-modal"
      :width="620"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="modal">
        <div class="modal__title">Массовое изменение депозитов</div>
        <div class="modal__body">
          <form>
            <app-form-group label="Дата, время начала" label-for="time_start_2" required>
              <app-input
                v-model="flying_form.time_start"
                v-mask="'99.99.9999 99:99'"
                placeholder="Период с"
                id="time_start_2"
                :error="$v.flying_form.time_start.$error"
              />
              <template #error>
                <div v-if="$v.flying_form.time_start.$dirty && !$v.flying_form.time_start.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group label="Дата, время окончания" label-for="time_end_2" required>
              <app-input
                v-model="flying_form.time_end"
                v-mask="'99.99.9999 99:99'"
                placeholder="Период по"
                id="time_end_2"
                :error="$v.flying_form.time_end.$error"
              />
              <template #error>
                <div v-if="$v.flying_form.time_end.$dirty && !$v.flying_form.time_end.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group label="Тип депозита" required>
              <app-cells position="start">
                <app-radio
                  v-model="flying_form.type.id"
                  value="pperson"
                  name="deposit_type_2"
                  label="За человека"
                  :error="$v.flying_form.type.$error"
                />
                <app-radio
                  v-model="flying_form.type.id"
                  value="ptable"
                  name="deposit_type_2"
                  label="За стол"
                  :error="$v.flying_form.type.$error"
                />
              </app-cells>
              <template #error>
                <div v-if="$v.flying_form.type.id.$dirty && !$v.flying_form.type.id.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group label="Размер депозита" label-for="price_2" required>
              <app-input
                v-model="flying_form.price"
                placeholder="Введите размер депозита"
                id="price_2"
                :error="$v.flying_form.price.$error"
              />
              <template #error>
                <div v-if="$v.flying_form.price.$dirty && !$v.flying_form.price.required">Обязательное поле</div>
                <div v-if="$v.flying_form.price.$dirty && !$v.flying_form.price.decimal">Принимаются только числовые значения через точку</div>
              </template>
            </app-form-group>
            <app-cells>
              <app-button ref="submit" type="button" :disabled="$v.flying_form.$error">Сохранить</app-button>
            </app-cells>
          </form>
        </div>
        <div slot="top-right">
          <button @click="onFlyingModalClear" class="modal__close"></button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {getDeposits, postDeposit, putDeposit, getTables, deleteDeposit} from '@/http'
import { dateFormatting } from '@/helpers'
import { debounce, isObject } from 'lodash'
import { decimal, required } from 'vuelidate/lib/validators'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import ru from 'date-fns/locale/ru'
import { endOfWeek, format, startOfWeek } from 'date-fns'

export default {
  name: 'Deposit',
  components: { VueCtkDateTimePicker },
  data() {
    return {
      is_show_flying_block: false,
      checkedAll: false,
      checked: [],
      flying_form: { type: { id: null } },
      table: {
        items: [],
        columns: ['id', 'table.number', 'time_start', 'type.name', 'price'],
        options: {
          headings: {
            'id': 'ID',
            'table.number': 'Стол',
            'time_start': 'Время',
            'type.name': 'Тип депозита',
            'price': 'Размер депозита'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 60,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 1,
      },
      table_limit: 60,
      sidebarData: {},
      isAdd: false,
      show_sidebar: false,
      tableList: [],
      input_search: '',
      date_time_picker: {},
      date_time_picker_error: false,
      custom_shortcuts: [
        { key: 'thisWeek', label: 'Эта неделя', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Последняя неделя', value: '-isoWeek' },
        { key: 'last7Days', label: 'Последние 7 дней', value: 7 },
        { key: 'last30Days', label: 'Последние 30 дней', value: 30 },
        { key: 'thisMonth', label: 'Этот месяц', value: 'month' },
        { key: 'lastMonth', label: 'Последний месяц', value: '-month' },
        { key: 'thisYear', label: 'Этот год', value: 'year' },
        { key: 'lastYear', label: 'Последний год', value: '-year' }
      ]
    }
  },
  validations: {
    sidebarData: {
      table: { required },
      time_start: { required },
      time_end: { required },
      type: {
        id: { required }
      },
      price: { required, decimal }
    },
    flying_form: {
      time_start: { required },
      time_end: { required },
      type: {
        id: { required }
      },
      price: { required, decimal }
    }
  },
  created() {
    this.fetchItems(1, this.table_limit, this.input_search, format(startOfWeek(new Date(), {locale: ru}), 'yyyy-MM-dd'),
      format(endOfWeek(new Date(), {locale: ru}), 'yyyy-MM-dd'))
    getTables({search: '', active: '', hall: ''}).then(res => { this.tableList = res.data })
  },
  watch: {
    checkedAll(value) {
      if (value) this.checked = this.table.items.map(item => item.id)
      else this.checked = []
    },
    checked(value) {
      this.is_show_flying_block = value.length > 1
    },
    date_time_picker(value) {
      if (!value) {
        this.fetchItems(1, this.table_limit, this.input_search, '', '')
        this.date_time_picker_error = false
      }
    }
  },
  methods: {
    onCheckedTableItemsClick() {
      this.is_show_flying_block = false
      this.$modal.show('flying-modal')
    },
    onFlyingModalClear() {
      if (!this.checkedAll) {
        this.checked = []
      } else {
        this.checkedAll = false
        this.$refs.mainCh.checked = false
      }
      this.$modal.hide('flying-modal')
    },
    onCheckForm() {
      this.$v.sidebarData.$touch()
      if (this.$v.sidebarData.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (!this.isAdd) {
        putDeposit(this.sidebarData.id, this.normalizeForm())
          .finally(() => { this.$refs.submit.preload = false })
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Стол обновлен'
            })
          })
      } else {
        postDeposit(this.normalizeForm())
          .finally(() => { this.$refs.submit.preload = false })
          .then(() => {
            this.$refs.submit.preload = false
            this.show_sidebar = false
            this.fetchItems()
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Стол добавлен'
            })
          })
      }
    },
    normalizeForm() {
      const normalizedForm = { ...this.sidebarData }

      if (isObject(normalizedForm.table)) normalizedForm.table = normalizedForm.table.id
      if (isObject(normalizedForm.type)) normalizedForm.type = normalizedForm.type.id
      normalizedForm.time_start = dateFormatting(normalizedForm.time_start, 'normal-to-iso')
      normalizedForm.time_end = dateFormatting(normalizedForm.time_end, 'normal-to-iso')
      return normalizedForm
    },
    fetchItems(
      page = this.pagination.page,
      page_size = this.table_limit,
      search = this.input_search,
      time_start = this.date_time_picker ? dateFormatting(this.date_time_picker.start, 'normal-to-iso-small') : '',
      time_end = this.date_time_picker ? dateFormatting(this.date_time_picker.end, 'normal-to-iso-small') : '') {
      getDeposits({ page, page_size, search, time_start, time_end })
        .then(res => {
          this.table.items = res.data.results.map(deposit => ({
            ...deposit,
            time_start: dateFormatting(deposit.time_start, 'iso-to-normal'),
            time_end: dateFormatting(deposit.time_end, 'iso-to-normal')
          }))
          this.pagination.pages = res.data.pages
          this.pagination.count = res.data.count
        })
    },
    onIsHiddenPicker() {
      if (this.date_time_picker &&
        this.date_time_picker.hasOwnProperty('start') &&
        this.date_time_picker.start &&
        this.date_time_picker.hasOwnProperty('end') &&
        this.date_time_picker.end) {
        this.date_time_picker_error = false
        this.pagination.page = 1
        this.fetchItems(
          1, this.table_limit, this.input_search,
          dateFormatting(this.date_time_picker.start, 'normal-to-iso-small'),
          dateFormatting(this.date_time_picker.end, 'normal-to-iso-small')
        )
      } else if (this.date_time_picker &&
        this.date_time_picker.start &&
        !this.date_time_picker.end) {
        this.date_time_picker_error = true
      }
    },
    onRowClick(element) {
      // if (element.event.layerX < 100) return

      this.$v.sidebarData.$reset()
      this.isAdd = false
      this.show_sidebar = true
      this.sidebarData = { ...element.row }
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onFilterSearch(search) {
      this.pagination.page = 1
      this.searchFilter(search, this)
    },
    searchFilter: debounce((search, vm) => {
      vm.fetchItems(1, vm.table_limit, search, vm.date_time_picker ? vm.date_time_picker.start : '', vm.date_time_picker ? vm.date_time_picker.end : '')
    }, 350),
    onAddButtonHandler() {
      this.$v.sidebarData.$reset()
      this.sidebarData = { type: { id: null, name: null } }
      this.isAdd = true
      this.show_sidebar = true
    },
    onDelete() {
      deleteDeposit(this.sidebarData.id)
        .then(() => {
          this.fetchItems()
          this.show_sidebar = false
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Расписание депозита удалено'
          })
        })
        .finally(() => {
          this.$refs.delete.preload = false
        })
    }
  }
}
</script>
